import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Sanierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was du vor der Sanierung deiner Immobilie beachten solltest" showCalc={false} />
            <Article>
                <h2>Wieviel kostet eine Sanierung?</h2>
                <p>
                    Eine Sanierung kostet oft viel Zeit und Geld. Deshalb sollte sie sehr gut überlegt sein. Es ist
                    wichtig, dass du davor einen umfangreichen Plan erstellst, um den zeitlichen und finanziellen
                    Aufwand realistisch einzuschätzen. Sonst führt eine Sanierung oft schnell zu einer bösen
                    Überraschung. Wir geben dir in diesem Artikel Tipps, was du bei einer Sanierung unbedingt bedenken
                    solltest – ganz egal, ob es sich um deine Altbauwohnung oder dein neues Haus handelt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Sanieren oder renovieren – was ist der Unterschied?</h2>
                <p>
                    Zuallererst solltest du bestimmen, ob deine Wohnung oder dein Haus eine Generalsanierung benötigt,
                    oder ob nur einzelne Schäden beseitigt werden müssen. Möchtest du deine Altbauwohnung in ihren
                    ursprünglichen Zustand bringen und Mängel beheben, oder möchtest du dein ganzes Haus modernisieren?
                    Sobald du durch die Arbeiten eine Verbesserung der Qualität und eine Wertsteigerung erreichst,
                    handelt es sich schon um eine Sanierung. Wenn nur einzelne Teile der Wohnung oder des Hauses
                    repariert werden, handelt es sich lediglich um Renovierungsarbeiten.
                </p>
                <p>
                    Ob es dann tatsächlich eine Sanierung oder nur eine Renovierung werden soll, hängt von deinen
                    Wünschen, deinen finanziellen Mitteln und natürlich davon ab, was aus baulicher Sicht überhaupt Sinn
                    ergibt. Oft geschieht deshalb im Zuge einer Renovierung sowieso eine Sanierung. Beim Ersetzen
                    kaputter Teile der Wohnung durch intakte, sucht man sich schließlich lieber gleich die besseren aus.
                </p>
                <hr />

                <h2>Welche Arbeiten sind notwendig?</h2>
                <p>
                    Die Frage, welche Arbeiten in deiner Wohnung oder an deinem Haus zu erledigen sind, solltest du in
                    jedem Fall mit einem Fachmann oder einer Fachfrau besprechen. Zusätzlich dazu solltest du dir auch
                    gut überlegen, welche Investitionen sich wirklich lohnen. Wenn du deine Altbauwohnung zum Beispiel
                    noch viele Jahre selbst nutzen oder vermieten möchtest, sind eine Renovierung oder eine Sanierung
                    durchaus sinnvoll. Wenn du die Wohnung allerdings verkaufen möchtest, solltest du dir jede
                    Investition genau ausrechnen und gut überlegen, welcher Aufwand sich lohnt und was wirklich sein
                    muss. Sind die geplanten Arbeiten aufwendig und umfangreich, ist es oft klüger, sich für einen
                    Verkauf zu entscheiden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Welche Bereiche kann eine Sanierung umfassen?</h2>
                <p>
                    Abhängig von der Art deiner Immobilie, kann die Sanierung (oder Renovierung) unterschiedliche
                    Bereiche umfassen. Meistens handelt es sich um Arbeiten in den folgenden Bereichen:
                </p>
                <ul>
                    <li>Hausdachsanierung</li>
                    <li>Fenstertausch, Austausch der Türen</li>
                    <li>Erneuerung von Rohren und Leitungen</li>
                    <li>Arbeiten an den Mauern, Dämmung und Isolierung</li>
                    <li>Feuchtigkeitsschutz</li>
                    <li>Heizung</li>
                    <li>Elektroinstallationen</li>
                    <li>Sanitäranlagen</li>
                    <li>Malen und Tapezieren</li>
                    <li>Böden (Erneuerung oder Austausch)</li>
                </ul>
                <p>
                    Welche Arbeiten wirklich notwendig sind, solltest du aber nicht auf eigene Faust entscheiden,
                    sondern dich dabeivon jemandem beraten lassen, der sich wirklich auskennt. Jede Sanierung (oder
                    Renovierung) läuft unterschiedlich ab – und zwar abhängig von dem individuellen Zustand des Hauses
                    oder der Wohnung. Deswegen gibt es vor der fertigen Beurteilung der individuellen Situation
                    grundsätzlich nur allgemeine Richtlinien, an die man sich halten kann, wenn eine Renovierung oder
                    Sanierung ansteht.
                </p>
                <hr />

                <h2>Welche Kosten erwarten mich bei einer Sanierung?</h2>
                <p>
                    Die Kosten einer Sanierung sind von vielen unterschiedlichen Faktoren abhängig. Der wichtigste
                    Faktor ist wohl der, wieviel Arbeit du selbst machen kannst und wieviel von einem Fachmann oder
                    einer Fachfrau erledigt werden muss. Auch die gewünschte Qualität der Arbeit beeinflusst den Preis.
                </p>
                <p>
                    Ob du die Sanierungsarbeiten wirklich selbst erledigen möchtest, solltest du dir vorher gut
                    überlegen. Der Aufwand wird häufig unterschätzt und es passieren Fehler, die dann von einem Fachmann
                    ausgebessert werden müssen und dich letztendlich das doppelte kosten können. Viele Menschen
                    überschätzen ihre eigenen Fähigkeiten und unterschätzen das benötigte Wissen bzw. die notwendige
                    Erfahrung mit Renovierungs- und Sanierungsarbeiten, die einem erst ermöglicht, grobe Fehler zu
                    vermeiden. Oft richtet man selbst noch zusätzlichen Schaden an, so dass danach noch mehr Arbeit
                    ansteht, als davor. Suche dir wenn möglich Unterstützung, bevor du dich alleine in die Arbeit
                    stürzt.
                </p>
                <p>
                    Bei der Planung der Kosten solltest du bedenken, dass die Kosten bei einer Haussanierung oder der
                    Sanierung einer Wohnung am Ende oft größer sind, als anfangs geplant. Folgende Punkte solltest du
                    bei der Erstellung eines Kostenplans unbedingt beachten:
                </p>
                <ul>
                    <li>Alter der Wohnung oder des Hauses</li>
                    <li>Art und Umfang von Schäden</li>
                    <li>Größe der Immobilie und Größe der Bereiche, die renoviert/saniert werden müssen</li>
                    <li>Materialkosten</li>
                    <li>Planungskosten</li>
                    <li>Beratungskosten</li>
                    <li>Arbeitszeit</li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kostenlose Beratung holen
                    </a>
                </p>
                <hr />

                <h2>Wie finanziere ich eine Sanierung?</h2>
                <p>
                    Für die Sanierung kann ein{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    beantragt werden, ein sogenanntes Sanierungsdarlehen. Planst du große Modernisierungen deiner
                    Wohnung oder deines Hauses, wie z.B. neue Böden, Fenster, Elektroinstallationen, Erneuerung der
                    Sanitäranlagen oder neue Heizungen und Wärmedämmungen, dann kann es sich lohnen, ein gefördertes
                    Sanierungsdarlehen in Anspruch zu nehmen.
                </p>
                <p>
                    Dabei handelt es sich zwar oft um Kredite mit hypothekarischer Besicherung, genauso wie bei
                    Immobilienkrediten, doch es gibt Unterschiede in den gewährten Förderungen. Diese sind dann wieder
                    von Bundesland zu Bundesland unterschiedlich. Zum Beispiel kannst du einmalige Zuschüsse bekommen
                    oder sogar eine laufende Annuitätenstützung. Bei letzterer übernimmt das Land für dich die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    und auch Teile deiner Kreditrückzahlung. Die Höhe hängt dann von dem Anteil förderbarer
                    Investitionen ab.
                </p>
                <hr />

                <h2>Wann bekomme ich ein gefördertes Sanierungsdarlehen?</h2>
                <p>
                    Um einen geförderten{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    für deine Sanierung zu bekommen, muss es sich dabei um förderwürdige Investitionen handeln. Darüber
                    hinaus gibt es noch weitere Kriterien: Die geförderte Immobilie, egal ob Haus oder Wohnung, muss
                    dein Hauptwohnsitz sein. Außerdem solltest du Staatsbürger:in eines EWR-Staates sein und die
                    bestimmte Einkommensgrenze nicht überschreiten. Informiere dich also gut über Möglichkeiten zu
                    geförderten Sanierungsmaßnahmen in deinem jeweiligen Bundesland und stelle dann einen Antrag auf ein
                    Sanierungsdarlehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Welche Sanierungen werden gefördert?</h2>
                <h3>Energiesparmaßnahmen</h3>
                <p>
                    Die geförderten Sanierungsmaßnahmen unterscheiden sich in jedem Bundesland und auch die Angebote der
                    Banken sind nicht alle gleich. Doch trotzdem gibt es einige Sanierungen, die sehr häufig
                    förderwürdig sind. Grundsätzlich werden Energiesparmaßnahmen z.B. fast immer staatlich gefördert.
                </p>
                <p>Dazu zählen:</p>
                <ul>
                    <li>Thermische Sanierungen</li>
                    <li>Einbau einer Wärmepumpe</li>
                    <li>Solaranlagen</li>
                    <li>Zentralheizungsanlagen</li>
                </ul>
                <h3>Altbausanierungen</h3>
                <p>
                    Auch Altbausanierungen können staatlich gefördert werden. Es gibt gesetzlich vorgeschriebene
                    Mindeststandards, die erfüllt werden müssen, wenn eine Wohnung vermietet wird. Aber auch sonst gibt
                    es grundsätzlich zu erfüllende feuerpolizeiliche Maßnahmen. Deshalb können in der Altbauwohnung z.B.
                    neue Elektroinstallationen, neue Fenster oder eine Dachsanierung notwendig sein. Außerdem wird die
                    Erhaltung und Wiederherstellung des ursprünglichen Baustils alter Gebäude und Wohnungen staatlich
                    gefördert. Informiere dich deshalb vor deiner Altbausanierung unbedingt über mögliche Förderungen.
                </p>
                <p>
                    Bevor du also eine Sanierungsarbeit beginnst, gibt es einige Dinge, über die du dich informieren
                    solltest und einige Bereiche, bei denen eine gute Beratung dir später viel Zeit und Geld sparen
                    kann. Ein Überblick über geförderte Sanierungsmaßnahmen kann dir auch von einem Experten oder einer
                    Expertin gegeben werden. Gut informiert zu sein, lohnt sich hier besonders.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"sanierung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"sanierung"}
                heading={"Was du vor der Sanierung deiner Immobilie beachten solltest"}
            />
        </Layout>
    );
};

export default Sanierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.sanierung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
